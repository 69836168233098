<template>
  <v-list-group
    v-model="tournament.active"
    active-class="color3--text"
    append-icon="fas fa-angle-down"
    class="tList"
  >
    <template v-slot:activator>
      <v-list-item-title>{{tournament.name}}</v-list-item-title>
    </template>
    <!-- Tournament Home -->
      <v-list-item
        :to="{ name: view.admin ? 'league-admin' : 'league-brochure', params: {tournamentId: tournament.id} }"
        router
        exact
        exact-active-class="color3--text"
      >
        <v-list-item-content>
          <v-list-item-title>League Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    <!-- STREAMS -->
      <v-list-item
        v-if="tournament.props.includes('streaming') && tournament.mappedStreams.length"
        :to="{ name: 'league-streams', params: {tournamentId: tournament.id} }"
        router
        exact
        exact-active-class="color3--text"
      >
        <v-list-item-content>
          <v-list-item-title>Live Streams</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-icon color="error">fas fa-signal-stream</v-icon>
        </v-list-item-action>
      </v-list-item>
    <!-- Teams -->
      <v-list-item
        :to="{ name: 'league-teams', params: {tournamentId: tournament.id} }"
        router
        exact
        exact-active-class="color3--text"
      >
        <v-list-item-content>
          <v-list-item-title>Team Locator</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    <!-- Players -->
      <v-list-item
        :to="{ name: 'league-players', params: {tournamentId: tournament.id} }"
        router
        exact
        exact-active-class="color3--text"
        v-if="tournament.isShowcase"
      >
        <v-list-item-content>
          <v-list-item-title>Players</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    <!-- Schedule Manager -->
      <v-list-item
        v-if="view.adminAndRefs && false"
        :to="{ name: 'league-schedule', params: {tournamentId: tournament.id} }"
        router
        exact
        exact-active-class="color3--text"
      >
        <v-list-item-content>
          <v-list-item-title v-if="view.adminAndRefs">Schedule Manager</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    <!-- Courts -->
      <v-list-item
        v-if="view.adminAndRefs"
        :to="{ name: 'league-courts', params: {tournamentId: tournament.id} }"
        router
        exact
        exact-active-class="color3--text"
      >
        <v-list-item-content>
          <v-list-item-title v-if="view.adminAndRefs">Court Manager</v-list-item-title>
          <v-list-item-title v-else>Courts</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    <!-- Divisions -->
    <template v-for="division in divisionTabs">
      <!-- Division Home -->
        <v-list-item
          :key="division.id"
          router
          :to="{name: 'division-home', params: {tournamentId: tournament.id, divisionId: division.id}}"
          exact
          exact-active-class="color3--text"
        >
          <v-list-item-content>
            <v-list-item-title v-if="division.loading">
              <v-progress-linear :indeterminate="true" color="color3"></v-progress-linear>
            </v-list-item-title>
            <v-list-item-title v-else>
              {{ division.name }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action v-if="division.isCoaches">
            <v-chip small :color="chipColor" v-if="view.admin || division.showTeams">
              {{division.teamCount}}
            </v-chip>
            <v-icon v-else small>fas fa-eye-slash</v-icon>
          </v-list-item-action>
        </v-list-item>

      <template v-if="!division.loading">
          <v-list-item
            v-for="tab in division.tabs"
            :to="tab.to"
            :key="tab.key"
            router
            :exact="tab.exact"
            active-class="color3--text"
            exact-active-class="color3--text"
          >
            <v-list-item-action class="mr-2"></v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{tab.text}}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="tab.count || !tab.public">
              <v-chip small :color="chipColor" v-if="tab.count">
                {{tab.count}}
              </v-chip>
              <v-icon v-else small>fas fa-eye-slash</v-icon>
            </v-list-item-action>
          </v-list-item>

        <!-- ROUNDS -->
          <template v-for="round in division.rounds">
              <!-- POOLS -->
              <v-list-item
                  v-if="round.poolPlay"
                  :to="round.flight && round.flight.pools && round.flight.pools.length === 1 && round.flight.pools[0].id && (['Matches', 'Friday', 'Saturday', 'Sunday'].includes(round.name) || round.freePlay) ? {
                    name: 'pool-sheet',
                    params: {tournamentId: tournament.id, divisionId: division.id, dayId: round.id, poolId: round.flight.pools[0].id}
                  } :{
                    name: 'pools-home',
                    params: {tournamentId: tournament.id, divisionId: division.id, dayId: round.id}
                  }"
                  :key="`pools_${division.id}_${round.id}`"
                  router
                  active-class="color3--text"
                >
                  <v-list-item-action class="justify-center mr-2">
                    <v-icon small v-if="round.flight && round.flight.complete">far fa-check fa-fw</v-icon>
                    <v-icon small v-else-if="round.flight && round.flight.started">fas fa-caret-right fa-fw</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title class="align-center d-flex justify-space-between">
                      {{round.name}}{{view.admin && round.lockReady ? '*' : ''}} {{view.admin && tournament.props.includes('poolTypes') ? round.poolTypes : ''}}
                      <span class="caption" v-if="view.admin && round.waitingOn">
                        wait {{round.waitingOn}}
                      </span>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action v-if="(view.admin || round.published) && division.hydrated">
                    <v-chip small :color="chipColor">
                      {{(round.name === 'Matches' && round.pools.length === 1) || round.freePlay ? round.poolMatches.length : round.pools.length}}
                      {{!round.freePlay && view.admin && round.uncourtedPools.length > 0 ? '*' : ''}}
                    </v-chip>
                  </v-list-item-action>
                </v-list-item>
              <!-- BRACKETS -->
                <template v-if="round.bracket && round.bracketPlay">
                  <v-list-item
                    inactive
                    :key="`bracket_header${division.id}_${round.id}`"
                    v-if="round.bracket.isDoublex"
                  >
                    <v-list-item-content class="ml-6 grey--text">
                      {{round.name}}
                    </v-list-item-content>
                  </v-list-item>
                  <!-- Winners -->
                  <v-list-item
                    :to="{name: 'league-bracket-home', params: {tournamentId: tournament.id, divisionId: division.id, dayId: round.id}}"
                    :key="`bracket${division.id}_${round.id}`"
                    router
                    exact
                    exact-active-class="color3--text"
                  >
                  <!-- WB -->
                    <v-list-item-action class="justify-center mr-2">
                      <v-icon small v-if="round.bracket.winnersStatus.complete || round.locked">far fa-check fa-fw</v-icon>
                      <v-icon small v-else-if="round.bracket.winnersStatus.started">fas fa-caret-right fa-fw</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{!round.bracket.losers.length || round.bracket.hasPlaceBracket ? round.name : 'Winners Bracket'}}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="view.admin || round.published">
                      <v-list-item-action-text v-if="round.bracket.winnersStatus.started && !round.bracket.winnersStatus.complete && !round.locked">
                        In {{round.bracket.winnersStatus.current}}
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                  <!-- LB -->
                  <v-list-item
                    v-if="round.brackets[0].losers.length"
                    :to="{name: 'league-bracket-contenders', params: {tournamentId: tournament.id, divisionId: division.id, dayId: round.id}}"
                    :key="`bracket${division.id}_${round.id}_LB`"
                    router
                    exact
                    exact-active-class="color3--text"
                  >
                    <v-list-item-action class="justify-center mr-2">
                      <v-icon small v-if="round.bracket.losersStatus.complete">far fa-check fa-fw</v-icon>
                      <v-icon small v-else-if="round.bracket.losersStatus.started">fas fa-caret-right fa-fw</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{round.bracket.losersName}} Bracket
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="view.admin || round.published">
                      <v-list-item-action-text v-if="round.bracket.losersStatus.started && !round.bracket.losersStatus.complete">
                        In {{round.bracket.losersStatus.current}}
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
            </template>
          </template>
      </template>
    </template>

  </v-list-group>
</template>

<script>
import { mapGetters } from 'vuex'
import { getHsp } from '@/classes/Dark'
import uniqBy from 'lodash.uniqby'

export default {
  computed: {
    ...mapGetters(['user', 'tournament', 'view', 'color2IsDark', 'theme']),
    chipColor () {
      const hsp1 = getHsp(this.theme.color2)
      const hsp2 = getHsp(this.theme.color2Text)
      return hsp1 < hsp2 ? 'color2 color2Text--text' : 'color2Text color2--text'
    },
    publicDivisions () {
      var divs = this.tournament && this.tournament.publicDivisions
      var jProps = this.tournament && this.tournament.jProps && this.tournament.jProps.divisions
      return divs && jProps ? divs.filter(f => !(jProps[f.id] && jProps[f.id].hide)) : divs
    },
    divisionTabs () {
      const jProps = this.tournament.jProps
      return this.publicDivisions.map(d => {
        return {
          loading: d.loading,
          id: d.id,
          name: d.name,
          tabs: [
            {
              text: 'Settings',
              to: { name: 'league-division-home', params: { tournamentId: this.tournament.id, divisionId: d.id } },
              show: this.view.admin,
              key: `settings_${d.id}`,
              exact: true
            },
            {
              text: 'Schedule Manager',
              to: { name: 'league-rounds', params: { tournamentId: this.tournament.id, divisionId: d.id } },
              show: this.view.admin && !d.isCoaches,
              key: `rounds_${d.id}`,
              exact: true
            },
            {
              text: `${d.acceptanceOnly ? 'Accepted ' : ''}${d.teamLabel}`,
              to: { name: 'league-division-teams', params: { tournamentId: this.tournament.id, divisionId: d.id } },
              show: this.view.admin || (!d.isCS && (!d.acceptanceOnly || d.teamCount)),
              key: `teams_${d.id}`,
              count: this.view.admin || d.showTeams ? d.teamCount : null,
              exact: true
            },
            {
              text: d.acceptanceOnly ? 'Pending Teams' : 'Waitlist',
              to: { name: 'league-division-waitlist', params: { tournamentId: this.tournament.id, divisionId: d.id } },
              show: d.waitlistTeams.length && !d.isCS && !(this.view.public && d.hideWaitlist),
              key: `waitlist_${d.id}`,
              count: d.waitlistTeams.length,
              exact: true
            },
            ...(d.standings || []).map((s, i) => {
              return {
                text: s.title,
                to: `/league/${this.tournament.id}/division/${d.id}/standings/${i}`,
                show: d.leaguePlayRounds.length > 0 && (this.view.admin || s.published),
                key: `standings_${d.id}_${i}`,
                public: s.published,
                exact: true
              }
            }),
            {
              text: 'Regular Season',
              to: { name: 'league-play', params: { tournamentId: this.tournament.id, divisionId: d.id } },
              key: `league_play_${d.id}`,
              show: d.leaguePlayRounds.length > 0,
              public: true,
              exact: false
            }
          ].filter(f => f.show),
          rounds: uniqBy([...d.allBracketRounds, ...d.getTournamentStyleRounds(jProps)], 'id')
        }
      })
    }
  }
}
</script>
<style>
.tList .v-list-group__header {
  padding-left: 8px !important;
  padding-right: 2px !important;
}
</style>
